<script>
  export let size = 100;
  export let rotation = 0;
</script>

<style>
  .control-panel {
    position: absolute;
    top: 1em;
    left: 1em;
    background: #222;
    opacity: 0.75;
    border-radius: 0.75em;
    z-index: 1000;
  }

  .control {
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }

  label {
    color: white;
    margin-right: 1em;
  }
</style>

<div class="control-panel">
  <div class="control">
    <label for="width">Size</label>
    <input type="range" name="width" bind:value={size} min="0" max="100" />
  </div>
  <div class="control">
    <label for="rotation">Rotation</label>
    <input
      type="range"
      name="rotation"
      bind:value={rotation}
      min="0"
      max="360" />
  </div>
</div>
