<script>
  import Logo from "./logo.svelte";
  import Control from "./Control.svelte";

  let size;
  let rotation;
</script>

<style>
  main {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<main>
  <Control bind:size bind:rotation />
  <Logo {size} {rotation} />
</main>
