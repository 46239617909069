<script>
  export let size = 100;
  export let rotation = 0;
</script>

<style>
  svg {
    display: block;
    transform-origin: center;
  }
</style>

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 100 50"
  style="width: {size}%; transform: rotate({rotation}deg)">
  <defs>
    <filter id="B1">
      <feGaussianBlur in="SourceGraphic" stdDeviation="0.5" />
    </filter>
    <filter id="B2">
      <feGaussianBlur in="SourceGraphic" stdDeviation="0.2" />
    </filter>
    <filter id="B3">
      <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
    </filter>
    <linearGradient id="G1" gradientTransform="rotate(90)">
      <stop offset="5%" stop-color="#163688" />
      <stop offset="95%" stop-color="#4b7cdd" />
      <!-- <stop offset="5%" stop-color="midnightblue" />
    <stop offset="95%" stop-color="cornflowerblue" /> -->
    </linearGradient>
    <clipPath id="C1">
      <circle cx="50" cy="25" r="20" />
    </clipPath>
    <clipPath id="C2">
      <path d="M0 25Q50 -25,100 25Q50 75,0 25Z" />
    </clipPath>
  </defs>
  <g clip-path="url(#C2)">
    <path d="M0 25Q50 -25,100 25Q50 75,0 25Z" fill="url(#G1)" />
    <circle cx="50" cy="25" r="20" fill="black" />
    <circle
      cx="50"
      cy="25"
      r="19"
      fill="white"
      filter="url(#B3)"
      clip-path="url(#C1)" />
    <g id="pupil">
      <circle cx="50" cy="25" r="10" />
      <g transform="rotate(10 50 25)">
        <path
          d="M42.20577 20.5 A9 9 0 0 1 59 25Q51.10289 20.5, 42.20577 20.5Z"
          fill="grey"
          filter="url(#B1)" />
      </g>
      <circle cx="52.5" cy="22.5" r="1.75" fill="white" filter="url(#B2)" />
    </g>
    <path fill="url(#G1)">
      <animate
        attributeName="d"
        keyTimes="0;.1;.2;1"
        values="M0 25C50 -25,50 -25,100 25,100 25,100 20,100 20,50 -30,50 -30,0
        25Z; M0 25C50 75,50 75,100 25,100 25,100 20,100 20,50 -30,50 -30,0 25Z;
        M0 25C50 -25,50 -25,100 25,100 25,100 20,100 20,50 -30,50 -30,0 25Z; M0
        25C50 -25,50 -25,100 25,100 25,100 20,100 20,50 -30,50 -30,0 25Z;"
        dur="5s"
        repeatCount="indefinite" />
    </path>
  </g>
</svg>
